<template>
  <div id="foriegn-currency">
    <v-data-table
      :headers="headers"
      :items="foreignData"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      :server-items-length="itemsLength"
      :height="530"
      class="elevation-1 mb-5"
      hide-default-footer
      @update:page="optionsUpdated()"
      @update:sort-by="optionsUpdated()"
      @update:sort-desc="optionsUpdated()">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="capitalize">
              Foreign Currency
            </span>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical />
          <v-spacer />
          <search-box
            v-model="search"
            @on-search="searchCurrency($event)" />
          <v-dialog
            v-model="dialog"
            max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mb-1 ml-2"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on">
                New Item
              </v-btn>
            </template>
            <v-card>
              <validation-observer
                ref="foriegncurrency"
                tag="form"
                @submit.prevent="save()">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row v-if="dialog">
                      <v-col
                        v-for="(form, key) in formData"
                        :key="`formData-${key}`"
                        :cols="12">
                        <gw-text-field
                          v-model="formData[key]"
                          :rules="textFieldRules"
                          :label="key"
                          :readonly="modalLoading"
                          number />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeFormModal()">
                    Cancel
                  </v-btn>
                  <v-btn
                    :loading="modalLoading"
                    color="blue darken-1"
                    type="submit"
                    text>
                    Save
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.thb`]="{ item }">
        {{ item.thb | showFullPriceFormat() }}
      </template>
      <template v-slot:[`item.sgd`]="{ item }">
        {{ item.sgd | showFullPriceFormat() }}
      </template>
      <template v-slot:[`item.usd`]="{ item }">
        {{ item.usd | showFullPriceFormat() }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <gw-icon-hover
          small
          class="sku-table-bin"
          icon-name="mdi-delete"
          icon-hover="mdi-delete-empty"
          @click="removeAttribute(item)" />
      </template>
    </v-data-table>
    <v-pagination
      v-if="paginationEnable"
      v-model="options.page"
      :length="pageCount"
      :disabled="loading"
      total-visible="7" />
  </div>
</template>

<script>
import SearchBox from '@/components/SearchBox.vue'
import CurrencyProvider from '@/resources/CurrencyProvider'
import { mapActions } from 'vuex'

const CurrencyService = new CurrencyProvider()

export default {
  components: { SearchBox },
  data () {
    return {
      formData: {
        thb: null,
        sgd: null,
        usd: null
      },
      foreignData: [
        { thb: 990, sgd: 30 },
        { thb: 1500, sgd: 50 }
      ],
      headers: [
        {
          text: 'Baht',
          value: 'thb',
          width: 150,
          align: 'center'
        },
        {
          text: 'Singapore',
          value: 'sgd',
          width: 150,
          align: 'center'
        },
        {
          text: 'USD',
          value: 'usd',
          width: 150,
          align: 'center'
        },
        {
          text: 'Actions',
          value: 'actions',
          width: 150,
          align: 'center'
        }
      ],
      dialog: false,
      loading: false,
      itemsLength: 0,
      pageCount: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['thb'],
        sortDesc: [true]
      },
      isEdited: false,
      textFieldRules: {
        required: true,
        regex: /^[+-]?([0-9]*[.])?[0-9]+$/
      },
      foriegnIso: [],
      editingId: null,
      modalLoading: false,
      search: ''
    }
  },
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit Item' : 'New Item'
    },
    paginationEnable () {
      return this.pageCount > 1
    }
  },
  mounted () {
    this.getCurrencies()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    optionsUpdated () {
      this.getCurrencies(this.options)
    },
    async getCurrencies () {
      try {
        this.loading = true
        const { data } = await CurrencyService.getCurrencies({ ...this.options, search: this.search })
        this.itemsLength = data.total
        this.pageCount = data.pages
        this.initHeaders([
          { isoCode: 'thb', label: 'thb' },
          { isoCode: 'sgd', label: 'sgd' },
          { isoCode: 'usd', label: 'usd' }
        ])
        this.foreignData = data.results.map((each) => this.mapCurrency(each))
      } catch (error) {
        console.error(`[GET-CURRENCIES-ERROR]: ${error.message}`)
      } finally {
        this.loading = false
      }
    },
    initHeaders (foriegns = []) {
      this.foriegnIso = []
      const foriegnHeaders = foriegns.map((each) => {
        this.foriegnIso.push(each.isoCode)

        return {
          text: this.capitalize(each.label),
          value: each.isoCode,
          width: 150,
          align: 'center'
        }
      })

      this.headers = [
        {
          text: 'Baht',
          value: 'thb',
          width: 150,
          align: 'center'
        },
        ...foriegnHeaders,
        {
          text: 'Actions',
          value: 'actions',
          width: 150,
          align: 'center'
        }
      ]
    },
    closeFormModal () {
      this.dialog = false
      this.isEdited = false
      this.editingId = null
      this.modalLoading = false
      this.formData = {
        thb: null,
        sgd: null,
        usd: null
      }
    },
    editItem (item) {
      this.formData = {
        thb: item.thb,
        sgd: item.sgd,
        usd: item.usd
      }
      this.editingId = item.id
      this.isEdited = true
      this.dialog = true
    },
    capitalize (text) {
      return text ? `${text[0].toUpperCase()}${text.slice(1)}` : ''
    },
    mapCurrency (payload) {
      const obj = {
        ...payload,
        id: payload.id,
        thb: payload.thb
      }

      payload.foriegnCurrencies.forEach((each) => {
        obj[each.isoCode] = each.value ? each.value : null
      })

      return obj
    },
    async createCurrency (payload) {
      const { data } = await CurrencyService.createCurrency(this.mapForCreate(payload))
      return !!data.id
    },
    async updateCurrency (id, payload) {
      const { data } = await CurrencyService.updateCurrency(id, this.mapForCreate(payload))
      return !!data.id
    },
    mapForCreate (payload) {
      const keys = Object.keys(payload)
      const foriegnCurrencies = keys.map((key) => ({
        isoCode: key,
        label: key,
        value: payload[key]
      }))
      const mapped = {
        thb: payload.thb,
        foriegnCurrencies
      }

      return mapped
    },
    async save () {
      this.modalLoading = true
      const vaid = await this.$refs.foriegncurrency.validate()
      if (vaid) {
        let isSuscess = false
        let message = 'created'
        try {
          this.modalLoading = true
          if (this.isEdited) {
            isSuscess = await this.updateCurrency(this.editingId, this.formData)
            message = 'updated'
          } else {
            isSuscess = await this.createCurrency(this.formData)
          }
        } catch (error) {
          this.setSnackbar({
            value: true,
            message: `Error code ${error.code} : ${error.message}`,
            type: 'error'
          })
          console.error('Foriegn Currency Error', error)
        } finally {
          this.closeFormModal()
          if (isSuscess) {
            this.setSnackbar({
              value: true,
              message: this.$t(`snackbar.ForiegnCurrency ${message}`),
              type: 'success'
            })
            this.getCurrencies()
          }
        }
      }
      this.modalLoading = false
    },
    async removeAttribute (item) {
      this.setModal({
        value: true,
        title: 'RemoveForiegnCurrency',
        message: 'Do you want to remove the ForiegnCurrency?',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveForiegnCurrency(item.id)
      })
    },
    async confirmRemoveForiegnCurrency (id) {
      try {
        const { data } = await CurrencyService.deleteCurrency(id)
        if (data.id && data.status === 'deleted') {
          this.setSnackbar({
            value: true,
            message: this.$t('snackbar.ForiegnCurrency removed'),
            type: 'success'
          })
          if (this.options.page > 1 && this.foreignData.length < 2) {
            this.options.page--
          } else {
            this.getCurrencies()
          }
        } else {
          const error = {
            code: 400,
            message: 'Foriegn Currency ID not found or status not change'
          }
          throw error
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}`,
          type: 'error'
        })
        console.error('confirmRemoveForiegnCurrency', error)
      }
    },
    searchCurrency (value) {
      this.options.page = 1
      this.search = value

      this.$router.push({
        name: this.$route.name,
        query: { search: value }
      }).catch(() => {})
      this.getCurrencies()
    }
  }
}
</script>
